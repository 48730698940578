import React from 'react';
import './App.css';
import './Style.css';
import '@shopify/polaris/styles.css';
import Orders from './components/Orders';
import en from '@shopify/polaris/locales/en.json';
import { AppProvider } from '@shopify/polaris';
import { BrowserRouter, Link, Switch, Route } from "react-router-dom";

function AdapterLink({ url, ...rest }) {
  return <Link to={url} {...rest} />
}

function App() {
  return (
    <div className="App">
      <AppProvider i18n={en} linkComponent={AdapterLink}>
        <BrowserRouter>
          <MyRouter />
        </BrowserRouter>
      </AppProvider>
    </div>
  );
}

function MyRouter() {
  return (
    <Switch>
      <Route exact path="/">
        <Orders />
      </Route>
    </Switch>
  );
}

export default App;
