import { GET_ORDERS, MANUAL_SYNC, ROUTE4ME, ROUTE_SUMMARY, LOAD_R4M, LOAD_SUMMARY, GET_ROUTES, LOAD_ROUTES, FAILED } from "../actions/orderAction";
const INITIAL_STATE = {
    get_orders: { message: [], loaded_order: false, total_records: 0 },
    manual_sync: { status: "", loaded_sync: false },
    r4me: { routes: [], optimization_problem_id: '', loaded_r4m: false, links: "" },
    get_routes: { data: [], loaded_routes: false },
    route_summary: { summary: [], order_nos: [], loaded_summary: false },
};
const handleOrders = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_ORDERS:
            {
                return Object.assign({}, state, {
                    get_orders:
                        { message: action.data.message, total_records: action.data.total_records, loaded_order: true }
                })
            }
        case MANUAL_SYNC:
            {
                return Object.assign({}, state, {
                    manual_sync: { status: action.data.status, loaded_sync: action.data.loaded_sync, data: action.data.data, success: action.data.success }
                })
            }
        case ROUTE4ME:
            {
                return Object.assign({}, state, {
                    r4me: { routes: action.data.routes, optimization_problem_id: action.data.optimization_problem_id, loaded_r4m: true, links: action.data.links }
                })
            }
        case GET_ROUTES:
            {
                return Object.assign({}, state, {
                    get_routes: { data: action.data.data, loaded_routes: true }
                })
            }
        case LOAD_ROUTES:
            {
                return Object.assign({}, state, {
                    get_routes: { loaded_routes: action.data }
                })
            }
        case LOAD_R4M:
            {
                return Object.assign({}, state, {
                    r4me: { loaded_r4m: action.data }
                })
            }
        case ROUTE_SUMMARY:
            {
                return Object.assign({}, state, {
                    route_summary: { summary: action.data.summary, order_nos: action.data.order_nos, loaded_summary: true }
                })
            }
        case LOAD_SUMMARY:
            {
                return Object.assign({}, state, {
                    route_summary: { loaded_summary: action.data, summary: [], order_nos: [] }
                })
            }
        case FAILED:
            {
                return Object.assign({}, state, { error_msg: action.data.error_msg });
            }
        default:
            return state;
    }
}
export default handleOrders;    