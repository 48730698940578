import BaseService from './baseService';

export function getOrder(data) {
    return BaseService.post('/get_records', (data));
}
export function selectAllOrder(data) {
    return BaseService.post('/get_records', (data));
}
export function getRoutes(data) {
    return BaseService.post('/get_routes', data);
}
export function manuallySync(data) {
    return BaseService.post('/bulkQuery', (data));
}
export function checkRoute4meByQuery(data) {
    return BaseService.post('/check_route4me_by_query', (data));
}
export function getRouteSummery(data) {
    return BaseService.post('/get_route_summary', (data));
}
export function getOrderDetails(data) {
    return BaseService.post('/get_order_details', (data));
}
export function printHtml(data) {
    return BaseService.post('/print_html', data);
}
export function getLocations(data) {
    return BaseService.post('/get_locations', data);
}
