import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
// import Cookies from "js-cookie";

import * as serviceWorker from './serviceWorker';
import { Provider } from '@shopify/app-bridge-react';
import { Provider as ReduxProvider } from "react-redux";
import store from './store';

var cookie_data = localStorage.getItem("userData") ? JSON.parse(localStorage.getItem("userData")) : "";

const config = { host: cookie_data.host, apiKey: cookie_data.app_key, forceRedirect: true };

var hostname = window.location.hostname;
ReactDOM.render(
  hostname === "localhost" ?
    <ReduxProvider store={store}>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </ReduxProvider>
    : (localStorage.getItem("userData") ?
      <Provider config={config} >
        <ReduxProvider store={store}>
          <React.StrictMode>
            <App />
          </React.StrictMode>
        </ReduxProvider>
      </Provider > : <div>Install app</div>)
  ,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
