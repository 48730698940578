import React, { useCallback, useState, useEffect } from 'react';
import { ResourceList, ResourceItem, TextStyle, Card, Spinner, SkeletonBodyText } from '@shopify/polaris';
import RoutesModal from './RoutesModal';
import * as AllAction from '../actions/orderAction';
import { useSelector, useDispatch } from 'react-redux';
// import Cookies from "js-cookie";

function Routes() {

    const dispatch = useDispatch();
    var cookie_data = localStorage.getItem("userData") ?
        JSON.parse(localStorage.getItem("userData")) :
        { shop: "shaw-farm.myshopify.com", accessToken: "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" };

    const [userData] = useState(cookie_data);
    const get_routes = useSelector(state => state.orderReducer.get_routes.data);
    const loaded_routes = useSelector(state => state.orderReducer.get_routes.loaded_routes);

    // const optimization_problem_id = useSelector(state => state.orderReducer.r4me.optimization_problem_id);
    const route_summary = useSelector(state => state.orderReducer.route_summary);
    // const route_links = useSelector(state => state.orderReducer.r4me.links);

    const [singleRoute, setSingleRoute] = useState({});
    const [isSelectedRoute, setIsSelectedRoute] = useState(false);
    const [value, setValue] = useState({});

    const handleSelectedRouteChange = useCallback(() => setIsSelectedRoute(!isSelectedRoute), [isSelectedRoute]);
    const handleCloseSelectedRoutes = () => {
        handleSelectedRouteChange();
    };

    useEffect(() => {
        const data = {
            shop: userData.shop, accessToken: userData.accessToken, limit: 10
        }
        dispatch(AllAction.getRoutesAction(data));
    }, [])

    function handleSingleItem(item) {
        var data = {
            shop: userData.shop, accessToken: userData.accessToken,
            optimization_problem_id: item.optimization_problem_id, route_id: item.id

        }
        dispatch(AllAction.routeSummaryAction(data));
        setSingleRoute(item);
        setIsSelectedRoute(true);
        setValue({ [item.id]: true })

    }
    var item_m = loaded_routes && get_routes && Array.isArray(get_routes) && get_routes.length !== 0 ?
        get_routes.map((get_routes) => {
            if (Array.isArray(get_routes.routes)) {
                if (get_routes.routes.length !== 0) {
                    return get_routes.routes.map((routes, k) => {
                        return { id: routes.route_id, optimization_problem_id: get_routes.optimization_problem_id, route_name: routes.parameters.route_name }
                    })
                }
            }
        }).flat() : [];

    var item_f = item_m && Array.isArray(item_m) && item_m.length !== 0 ?
        item_m.filter((item_m) => {
            if (item_m) {
                return item_m
            }
        }) : []

    var item = item_f && Array.isArray(item_f) && item_f.length !== 0 ? item_f.map((item_f, i) => {
        return { id: item_f.id, optimization_problem_id: item_f.optimization_problem_id, key: i, route_name: item_f.route_name ? item_f.route_name : "" }

    }) : [];

    // function handleURLClick() {
    //     if (route_links && route_links.view) {
    //         window.open(route_links.view, "_blank")
    //     }
    // }

    return (<>
        {loaded_routes && item && Array.isArray(item) && item.length !== 0 ? <Card>

            {/* <div className="route-url"><Button onClick={() => handleURLClick()}>URL</Button></div> */}

            <ResourceList
                resourceName={{ singular: 'customer', plural: 'customers' }}
                items={item}
                renderItem={(item) => {
                    const { id, route_name } = item;
                    return (
                        <ResourceItem
                            id={id}
                            onClick={() => handleSingleItem(item)}>
                            <h3>
                                <div className="route-li">
                                    <TextStyle variation="strong">#{id} - {route_name}</TextStyle>
                                    {
                                        value[item.id] === true && (route_summary && route_summary.loaded_summary === false) &&
                                        <span className='route-spinner'><Spinner accessibilityLabel="Small spinner example" size="small" color="inkLightest" />
                                        </span>
                                    }
                                </div>
                            </h3>
                        </ResourceItem>
                    );
                }}
            />
        </Card> :
            <Card sectioned>
                <Card.Section>
                    <SkeletonBodyText lines={1} />
                </Card.Section>
                <Card.Section>
                    <SkeletonBodyText lines={1} />
                </Card.Section>
                <Card.Section>
                    <SkeletonBodyText lines={1} />
                </Card.Section>
            </Card>}
        {isSelectedRoute && (route_summary && route_summary.loaded_summary === true) &&
            <RoutesModal isSelectedRoute={isSelectedRoute} handleCloseSelectedRoutes={handleCloseSelectedRoutes} singleRoute={singleRoute} />}
    </>)
}
export default Routes;