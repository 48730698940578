import React, { useCallback, useState, useEffect } from 'react';
import {
    Page, Card, ResourceList, Filters, ChoiceList, DatePicker, Badge, Toast, ButtonGroup,
    SkeletonBodyText, ResourceItem, Pagination, Select, Icon, Button, Spinner, Modal, Stack, TextField, Tabs, Frame
} from '@shopify/polaris';
import { RefreshMajorMonotone } from '@shopify/polaris-icons';
import * as AllAction from '../actions/orderAction';
import { useSelector, useDispatch } from 'react-redux';
import * as authService from "../services/authService";
// import Cookies from "js-cookie";
import moment from 'moment';
import Routes from './Routes';

function Order() {
    const get_orders = useSelector(state => state.orderReducer.get_orders);
    const manual_sync = useSelector(state => state.orderReducer.manual_sync);
    const r4me = useSelector(state => state.orderReducer.r4me);

    const dispatch = useDispatch();
    var cookie_data = localStorage.getItem("userData") ?
        JSON.parse(localStorage.getItem("userData")) :
        { shop: "shaw-farm.myshopify.com", accessToken: "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" };

    const [userData] = useState(cookie_data);
    const [queryValue, setQueryValue] = useState(null);
    const [selectedItems, setSelectedItems] = useState([]);
    const [sortValue, setSortValue] = useState('date=desc');
    const [selectedPages, setSelectedPages] = useState(50);
    const [location, setlocation] = useState(null);
    const [paymentStatus, setPaymentStatus] = useState(null);
    const [fulfillmentStatus, setFulfillmentStatus] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [syncCLick, setSyncClick] = useState(false);
    const [active, setActive] = useState(false);
    const [clickedR4M, setClickedR4M] = useState(false);
    const [parts, setParts] = useState('2');
    const [maxTourSize, setMaxTourSize] = useState('70');
    const [routeName, setRouteName] = useState('');
    const [isDepButtonActive, SetIsDepButtonActive] = useState(true);

    const [routeDate, setRouteDate] = useState('');
    const [routeTime, setRouteTime] = useState('0');
    const [loadLoc, setLoadLoc] = useState(false);
    var [allLoc, setAllLoc] = useState([]);

    const [{ month, year }, setDate] = useState({
        month: parseInt(moment().format("MM") - 1),
        year: parseInt(moment().format("YYYY")),
    });

    const [selected, setSelected] = useState(0);

    const [selectedDates, setSelectedDates] = useState({
        start: new Date(),
        end: new Date(),
        date: null
    });

    const handleModalChange = useCallback(() => setActive(!active), [active]);
    const handleClose = () => { handleModalChange(); };
    const handleTabChange = useCallback((selectedTabIndex) => setSelected(selectedTabIndex), []);
    const handleMonthChange = useCallback(
        (month, year) => setDate({ month, year }),
        [],
    );
    useEffect(() => {
        setLoadLoc(false);
        var data = {
            shop: userData.shop, accessToken: userData.accessToken
        }
        authService.getLocations(data).then((response) => {
            setLoadLoc(true);
            if (response.data) {

                var get_loc = response.data && response.data.message && Array.isArray(response.data.message) ? response.data.message.map((loc) => {
                    return { value: loc, label: loc }
                }) : []
                setAllLoc(get_loc);
            }


        }).catch(err => {
            setLoadLoc(true);

            console.log(err)
        })
    }, [])

    useEffect(() => {
        apiCall();
    }, [queryValue, sortValue, location, paymentStatus, fulfillmentStatus, selectedDates, selectedPages]);

    const handleSelectChange = useCallback((value) => setSelectedPages(value), []);
    const handlelocationChange = useCallback(value => setlocation(value), []);
    const handlePaymentStatusChange = useCallback(value => setPaymentStatus(value), []);
    const handleFulfillmentStatusChange = useCallback(value => setFulfillmentStatus(value), []);
    const handleSelectDateChange = useCallback((start, end) => setSelectedDates(start, end, 'true'), []);

    const handleFiltersQueryChange = useCallback(
        (value) => setQueryValue(value),
        []
    );
    const handlelocationRemove = useCallback(
        () => setlocation(null),
        []
    );
    const handlePaymentStatusRemove = useCallback(
        () => setPaymentStatus(null),
        []
    );
    const handleFulfillmentStatusRemove = useCallback(
        () => setFulfillmentStatus(null),
        []
    );
    const handleSelectedDateRemove = useCallback(
        () => setSelectedDates({
            start: new Date(),
            end: new Date(),
            date: null
        }),
        []
    );

    const handleQueryValueRemove = useCallback(() => setQueryValue(null), []);
    const handleFiltersClearAll = useCallback(() => {
        handlelocationRemove();
        handlePaymentStatusRemove();
        handleFulfillmentStatusRemove();
        handleSelectedDateRemove();
    }, [
        handlelocationRemove,
        handlePaymentStatusRemove,
        handleFulfillmentStatusRemove,
        handleSelectedDateRemove
    ]);
    const handleManualSync = () => {
        setCurrentPage(1)
        setSyncClick(true)
        const data = {
            shopOrigin: userData.shop, accessToken: userData.accessToken
        }
        var common = queryValue ? queryValue : "";
        var location1 = location ? location : [];
        var sorted = "";
        if (sortValue && sortValue !== "") {
            var sort_val = sortValue.split("=");
            var sort_key = sort_val[0]
            sorted = { [sort_key]: sort_val[1] }
        }
        var payment_status = paymentStatus && paymentStatus[0] ? paymentStatus[0] : "";
        var fulfillment_satus = fulfillmentStatus && fulfillmentStatus[0] ? fulfillmentStatus[0] : "";
        var start = selectedDates.date !== null && selectedDates.start ? moment(selectedDates.start).format('YYYY-MM-DD') : "";
        var end = selectedDates.date !== null && selectedDates.end ? moment(selectedDates.end).format('YYYY-MM-DD') : "";

        const data_order = {
            shop: userData.shop, accessToken: userData.accessToken,
            search: { common, location: location1, payment_status, fulfillment_satus, delivery_date: { start, end } },
            limit: parseInt(selectedPages),
            page: 1,
            sort: sorted

        }
        dispatch(AllAction.manuallySyncAction(data, data_order));
    }
    const filters = [
        {
            key: "location",
            label: "Location",
            filter: (
                <ChoiceList
                    allowMultiple
                    title="Location"
                    titleHidden
                    choices={allLoc}
                    selected={location || []}
                    onChange={handlelocationChange}
                />
            ),
            shortcut: true
        },
        {
            key: "paymentStatus",
            label: "Payment status",
            filter: (
                <ChoiceList
                    title="Payment status"
                    titleHidden
                    choices={[
                        { label: "Authorized", value: "AUTHORIZED" },
                        { label: "Paid", value: "PAID" },
                        { label: "Partially paid", value: "PARTIALLY_PAID" },
                        { label: "Pending", value: "PENDING" },
                        { label: "Refunded", value: "REFUNDED" },
                        { label: "partially_refunded", value: "PARTIALLY_REFUNDED" },
                        { label: "Voided", value: "VOIDED" },
                    ]}
                    selected={paymentStatus || []}
                    onChange={handlePaymentStatusChange}
                />
            ),
            shortcut: true
        },
        {
            key: "fulfillmentStatus",
            label: "Fulfillment status",
            filter: (
                <ChoiceList
                    title="Fulfillment status"
                    titleHidden
                    choices={[
                        { label: "Fulfilled", value: "FULFILLED" },
                        { label: "Unfulfilled", value: "UNFULFILLED" },
                        { label: "Partially fulfilled", value: "PARTIAL" },
                    ]}
                    selected={fulfillmentStatus || []}
                    onChange={handleFulfillmentStatusChange}

                />
            ),
            shortcut: true
        },
        {
            key: "selectedDates",
            label: "Delivery Date",
            filter: (
                <DatePicker
                    month={month}
                    year={year}
                    onChange={handleSelectDateChange}
                    onMonthChange={handleMonthChange}
                    allowRange
                    multiMonth
                    selected={selectedDates}
                />
            ),
            shortcut: true
        },
    ];

    var Route4me_content = <div>Route4me {(clickedR4M === true) &&
        (r4me.loaded_r4m === false) &&
        <span className="r4m-spinner"><Spinner size="small" color="inkLightest" /></span>}</div>


    const promotedBulkActions = [
        {
            content: Route4me_content,
            onAction: () => setActive(true),
        },
    ];
    const bulkActions = [];
    const appliedFilters = [];
    if (!isEmpty(location)) {
        const key = "location";
        appliedFilters.push({
            key,
            label: disambiguateLabel(key, location),
            onRemove: handlelocationRemove
        });
    }
    if (!isEmpty(paymentStatus)) {
        const key = "paymentStatus";
        appliedFilters.push({
            key,
            label: disambiguateLabel(key, paymentStatus),
            onRemove: handlePaymentStatusRemove
        });
    }
    if (!isEmpty(fulfillmentStatus)) {
        const key = "fulfillmentStatus";
        appliedFilters.push({
            key,
            label: disambiguateLabel(key, fulfillmentStatus),
            onRemove: handleFulfillmentStatusRemove
        });
    }
    if (selectedDates.date !== null) {
        const key = "selectedDates";
        appliedFilters.push({
            key,
            label: disambiguateLabel(key, selectedDates),
            onRemove: handleSelectedDateRemove
        });
    }

    var get_all_orders = get_orders.message
    const items = get_all_orders && Array.isArray(get_all_orders) && get_all_orders.length !== 0 ?
        get_all_orders.map((get_all_orders) => {
            var customer = get_all_orders.customer && get_all_orders.customer.displayName ?
                get_all_orders.customer.displayName : "";
            var date = get_all_orders.createdAt ?
                moment(get_all_orders.createdAt).format('YYYY-MM-DD') : "";
            var amount = get_all_orders.originalTotalPriceSet && get_all_orders.originalTotalPriceSet.shopMoney ?
                (get_all_orders.originalTotalPriceSet.shopMoney.amount ?
                    get_all_orders.originalTotalPriceSet.shopMoney.amount : "") +
                (get_all_orders.originalTotalPriceSet.shopMoney.currencyCode ?
                    (" " + get_all_orders.originalTotalPriceSet.shopMoney.currencyCode) : "") : "";
            var payment = get_all_orders.displayFinancialStatus ?
                <Badge status={get_all_orders.displayFinancialStatus === 'PENDING' && 'warning'}>{get_all_orders.displayFinancialStatus.toLowerCase()}</Badge> : "";
            var fulfillment = get_all_orders.displayFulfillmentStatus ?
                <Badge status={get_all_orders.displayFulfillmentStatus === 'UNFULFILLED' && 'attention'}>{get_all_orders.displayFulfillmentStatus.toLowerCase()}</Badge>
                : "";
            var deliveryDate = get_all_orders.tag_deliverydate ? get_all_orders.tag_deliverydate : "";
            var location = get_all_orders.tag_location ? get_all_orders.tag_location : "";

            return { id: get_all_orders._id, order: get_all_orders.name, date, customer, amount, payment, fulfillment, deliveryDate, location }
        })
        : [];

    function apiCall(page) {
        if (!page) {
            setCurrentPage(1)
        }
        var common = queryValue ? queryValue : "";
        var location1 = location ? location : [];
        var sorted = "";
        if (sortValue && sortValue !== "") {
            var sort_val = sortValue.split("=");
            var sort_key = sort_val[0]
            sorted = { [sort_key]: sort_val[1] }
        }
        var payment_status = paymentStatus && paymentStatus[0] ? paymentStatus[0] : "";
        var fulfillment_satus = fulfillmentStatus && fulfillmentStatus[0] ? fulfillmentStatus[0] : "";
        var start = selectedDates.date !== null && selectedDates.start ? moment(selectedDates.start).format('YYYY-MM-DD') : "";
        var end = selectedDates.date !== null && selectedDates.end ? moment(selectedDates.end).format('YYYY-MM-DD') : "";
        var data = {
            shop: userData.shop, accessToken: userData.accessToken,
            search: { common, location: location1, payment_status, fulfillment_satus, delivery_date: { start, end } },
            limit: parseInt(selectedPages),
            page: page ? (page < 1 ? 1 : page) : 1,
            sort: sorted
        }
        dispatch(AllAction.getOrderAction(data));
    };

    function handleSetRouteDate(value) {

        setRouteDate(value)
    };

    function hasNext() {
        var total = 1;
        if (get_orders.total_records > selectedPages) {
            if (get_orders.total_records > 0) {
                var total_page = parseInt(get_orders.total_records) / parseInt(selectedPages)
                if (total_page > 0) {
                    if (get_orders.total_records % selectedPages !== 0) {
                        total = Math.floor(total_page) + 1
                    } else {
                        total = Math.floor(total_page)
                    }
                    if (total === currentPage) {
                        return false;
                    }
                    else {
                        return true;
                    }
                }
            } else {
                return false;
            }
        } else {
            return false;
        }
    };

    async function handleRoute4me() {

        setClickedR4M(true)
        handleClose();

        var orders = (get_orders.loaded_order === true && get_orders.message && Array.isArray(get_orders.message) && get_orders.message.length !== 0) ? get_orders.message : []
        var selected_order = [];
        if (selectedItems !== 'All') {
            if (orders.length !== 0 && selectedItems.length !== 0) {
                for (var i = 0; i < orders.length; i++) {
                    for (var j = 0; j < selectedItems.length; j++) {
                        if (orders[i]._id === selectedItems[j]) {
                            selected_order.push(orders[i])
                        }
                    }
                }
            }
        } else {

            var common = queryValue ? queryValue : "";
            var location = location ? location : [];
            var sorted = "";
            if (sortValue && sortValue !== "") {
                var sort_val = sortValue.split("=");
                var sort_key = sort_val[0]
                sorted = { [sort_key]: sort_val[1] }
            }
            var payment_status = paymentStatus && paymentStatus[0] ? paymentStatus[0] : "";
            var fulfillment_satus = fulfillmentStatus && fulfillmentStatus[0] ? fulfillmentStatus[0] : "";
            var start = selectedDates.date !== null && selectedDates.start ? moment(selectedDates.start).format('YYYY-MM-DD') : "";
            var end = selectedDates.date !== null && selectedDates.end ? moment(selectedDates.end).format('YYYY-MM-DD') : "";
            var data = {
                shop: userData.shop, accessToken: userData.accessToken,
                search: { common, location, payment_status, fulfillment_satus, delivery_date: { start, end } },
                limit: get_orders.total_records ? parseInt(get_orders.total_records) : 50,
                page: 1,
                sort: sorted
            }
            await authService.selectAllOrder(data)
                .then((response) => {

                    if (response.status === 200) {
                        if (response.data && (response.data.message) && (Array.isArray(response.data.message))) {
                            if (response.data.message.length !== 0) {

                                selected_order = response.data.message

                            } else {
                                console.log("no data received from API.")
                            }

                        } else {
                            console.log("no data received from API.")
                        }

                    } else {
                        console.log("no data received from API.")
                    }
                })
                .catch((error) => {
                    if (error) {
                        console.log(error)
                    }
                })
        }


        var addresses = selected_order && Array.isArray(selected_order) && selected_order.length !== 0 ? selected_order.map((get_orders) => {
            var shippingAddress = get_orders.shippingAddress || {};
            var message = `${shippingAddress.address1 ? shippingAddress.address1 : ''}\
${shippingAddress.address2 ? ' ' + shippingAddress.address2 : ''}\
${shippingAddress.city ? ', ' + shippingAddress.city : ''}\
${shippingAddress.province ? ', ' + shippingAddress.province : ''}\
${shippingAddress.zip ? ' ' + shippingAddress.zip : ''}\
${shippingAddress.country ? ' ' + shippingAddress.country : ''}`;

            if (shippingAddress?.geocodedAddress) {
                message = shippingAddress.geocodedAddress;
            }
            var lat = get_orders.shippingAddress ? shippingAddress.latitude : null;
            var lng = get_orders.shippingAddress ? shippingAddress.longitude : null;
            var order_no = get_orders.name;
            var cost = get_orders.totalPrice;

            return {
                address: message, lat, lng, alias: null, is_depot: "false", time: "0", order_no, cost, cube: null, "invoice_no": null,
                "pieces": null,
                "reference_no": order_no, "revenue": null,
                "weight": null,
                "first_name": null,
                "last_name": null,
                "email": get_orders.email,
                "phone": shippingAddress ? shippingAddress.phone : null,
                "customer_po": null,
                "group": null
            }
        }) : [];

        var first_address = {
            address: "204 New Boston Road, Dracut, MA 01826",
            lat: 42.681579,
            lng: -71.332526,
            alias: null,
            is_depot: isDepButtonActive ? "true" : "false", time: "0", order_no: null, cost: null, cube: null, "invoice_no": null,
            "pieces": null,
            "reference_no": null, "revenue": null,
            "weight": null,
            "first_name": null,
            "last_name": null,
            "email": 'lyndieashaw@gmail.com',
            "phone": "9789570031",
            "customer_po": null,
            "group": null
        }
        addresses.unshift(first_address);

        var data_r4m = {
            shop: userData.shop, accessToken: userData.accessToken,
            addresses,
            "parameters": {
                "algorithm_type": "3",
                "parts": parts && parts > 0 ? parts.toString() : '2',
                "parts_min": parts && parts > 0 ? parts.toString() : '2',
                "route_max_duration": "86400",
                "vehicle_max_distance_mi": null,
                "vehicle_capacity": null,
                "vehicle_max_cargo_weight": null,
                "vehicle_max_cargo_volume": null,
                "subtour_max_revenue": null,
                "min_tour_size": null,
                "max_tour_size": maxTourSize && maxTourSize > 0 ? maxTourSize.toString() : '70',
                "rt": "true",
                "route_name": routeName,
                "route_date": routeDate !== '' ? (new Date(routeDate).getTime() / 1000).toString() : null,
                "route_time": routeTime !== "0" ? (moment.duration(routeTime).asSeconds()).toString() : null,
                "distance_unit": "mi",
                "travel_mode": "Driving",
                "directions": "false",
                "optimize": "Distance",
            }
        }
        dispatch(AllAction.route4meAction(data_r4m));
    }

    var firstItem = (items && get_orders.loaded_order === true && Array.isArray(items) && items.length !== 0) ? items[0] : "";
    const optionsPages = [
        { label: '50', value: '50' },
        { label: '100', value: '100' },
        { label: '150', value: '150' },
        { label: '200', value: '200' },
    ];
    var routes_content = <div>Routes {(clickedR4M === true) && (r4me.loaded_r4m === false) && <span className="r4m-spinner"><Spinner size="small" color="inkLightest" /></span>}</div>

    const tabs = [
        {
            id: 'orders',
            content: 'Orders'
        },
        {
            id: 'routes',
            content: routes_content
        }
    ];

    var route_date_label = routeDate !== "" ? "Route Date (" + new Date(routeDate).getTime() / 1000 + ")" : "Route Date"
    var route_time_label = routeTime !== "0" ? "Route Time (" + moment.duration(routeTime).asSeconds() + " sec)" : "Route Time"

    return (
        <div className="orders">
            <Frame>
                <Page
                    fullWidth
                    title="Orders">
                    <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange}>
                        <Card.Section >
                            {selected === 0 && <>
                                <Card>
                                    {(loadLoc && get_orders.loaded_order === true) ? <>
                                        <Card.Section>
                                            <Filters
                                                queryValue={queryValue}
                                                filters={filters}
                                                appliedFilters={appliedFilters}
                                                onQueryChange={handleFiltersQueryChange}
                                                onQueryClear={handleQueryValueRemove}
                                                onClearAll={handleFiltersClearAll}>
                                                <div className='select-sync'>
                                                    <div className="sync-btn">
                                                        <Select
                                                            options={optionsPages}
                                                            onChange={handleSelectChange}
                                                            value={selectedPages}
                                                        />
                                                    </div>
                                                    <div className="sync-btn">
                                                        <Button onClick={handleManualSync} disabled={syncCLick && manual_sync.loaded_sync !== true}>
                                                            {syncCLick && manual_sync.loaded_sync !== true ?
                                                                <Spinner accessibilityLabel="Small spinner example" size="small" color="teal" />
                                                                : <Icon source={RefreshMajorMonotone} />}
                                                        </Button>
                                                    </div>
                                                </div>
                                            </Filters>
                                        </Card.Section>
                                        <Card.Section>
                                            <>

                                                <ResourceList
                                                    totalItemsCount={get_orders && parseInt(get_orders.total_records)}
                                                    items={items}
                                                    renderItem={renderItem}
                                                    selectedItems={selectedItems}
                                                    onSelectionChange={setSelectedItems}
                                                    promotedBulkActions={promotedBulkActions}
                                                    bulkActions={bulkActions}
                                                    sortValue={sortValue}
                                                    showHeader
                                                    sortOptions={[
                                                        { label: 'Customer(A-Z)', value: 'by_customer=asc' },
                                                        { label: 'Customer(Z-A)', value: 'by_customer=desc' },
                                                        { label: 'Date(oldest first)', value: 'date=asc' },
                                                        { label: 'Date(newest first)', value: 'date=desc' },
                                                        { label: 'Amount(low to high)', value: 'amount=desc' },
                                                        { label: 'Amount(high to low)', value: 'amount=asc' },
                                                        { label: 'Delivery date(oldest first)', value: 'del_date=asc' },
                                                        { label: 'Delivery date(newest first)', value: 'del_date=desc' },
                                                    ]}
                                                    onSortChange={(selected) => {
                                                        setSortValue(selected);
                                                    }}
                                                    hasMoreItems={parseInt(get_orders.total_records) > parseInt(selectedPages) ? true : false}
                                                />

                                            </>
                                        </Card.Section>
                                    </> :
                                        <Card sectioned>
                                            <Card.Section>
                                                <SkeletonBodyText lines={1} />
                                            </Card.Section>
                                            <Card.Section>
                                                <SkeletonBodyText lines={1} />
                                            </Card.Section>
                                            <Card.Section>
                                                <SkeletonBodyText lines={1} />
                                            </Card.Section>
                                        </Card>}
                                    <Card.Section >
                                        <Pagination
                                            hasPrevious={(parseInt(currentPage) === 1) ? false : (get_orders.message.length > 0 ? true : false)}
                                            previousTooltip="Previous"
                                            onPrevious={() => {
                                                apiCall(parseInt(currentPage) - 1)
                                                setCurrentPage(parseInt(currentPage) - 1)
                                            }}
                                            hasNext={hasNext()}
                                            nextTooltip="Next"
                                            onNext={() => {
                                                apiCall(parseInt(currentPage) + 1)
                                                setCurrentPage(parseInt(currentPage) + 1)
                                            }}
                                        />
                                    </Card.Section>

                                </Card>
                            </>}
                            {selected === 1 && <Routes />}
                        </Card.Section>
                    </Tabs>
                    {syncCLick && manual_sync.loaded_sync === true && manual_sync.success === false &&
                        <Toast content={"Error! " + manual_sync.data} error onDismiss={() => setSyncClick(false)} />}
                </Page>
                <Modal
                    open={active}
                    onClose={handleClose}
                    title="Configuration"
                    primaryAction={{
                        content: 'Ok',
                        onAction: parts > 0 && maxTourSize > 0 && handleRoute4me,
                    }}
                    secondaryActions={[
                        {
                            content: 'Cancel',
                            onAction: handleClose,
                        },
                    ]}
                >
                    <Modal.Section>
                        <Stack vertical>
                            <Stack.Item fill>
                                <div className="config-modal">
                                    <div className="route-input">
                                        <TextField
                                            label="Route Name"
                                            type='text'
                                            min={0}
                                            value={routeName}
                                            onChange={(value) => { setRouteName(value) }}
                                        />
                                    </div>
                                    <div className="route-input">
                                        <TextField
                                            label="Parts"
                                            type='number'
                                            min={0}
                                            value={parts}
                                            onChange={(value) => { setParts(value) }}
                                            error={(parts < 0) && "Must be greater than 0!"}
                                        />
                                    </div>
                                    <div className="route-input">
                                        <TextField
                                            label="Max tour size"
                                            type='number'
                                            min={0}
                                            value={maxTourSize}
                                            onChange={(value) => { setMaxTourSize(value) }}
                                            error={maxTourSize < 0 && "Must be greater than 0!"}
                                        />
                                    </div>
                                </div>
                                <div className="config-modal">
                                    <div className="route-input">
                                        <TextField
                                            label={route_date_label}
                                            type='date'
                                            onChange={(value) => { handleSetRouteDate(value) }}
                                            value={routeDate}
                                        />
                                    </div>
                                    <div className="route-input">
                                        <TextField
                                            label={route_time_label}
                                            type='time'
                                            value={routeTime}
                                            onChange={(value) => { setRouteTime(value) }}
                                            error={routeTime < 0 && "Must be greater than 0"}
                                        />
                                    </div>
                                    {/* <div className="dep">
                                        <TextField
                                            label='Departure'
                                            readOnly
                                        />
                                        <ButtonGroup segmented>
                                            <Button pressed={isDepButtonActive} onClick={() => SetIsDepButtonActive(true)}>
                                                True
                                            </Button>
                                            <Button pressed={!isDepButtonActive} onClick={() => SetIsDepButtonActive(false)}>
                                                False
                                        </Button>
                                        </ButtonGroup>
                                    </div> */}
                                </div>
                            </Stack.Item>
                        </Stack>
                    </Modal.Section>
                </Modal>
                {/* <RoutesModal activeRoutes={activeRoutes} handleCloseRoutes={handleCloseRoutes} /> */}
            </Frame>
        </div>
    );

    function disambiguateLabel(key, value) {
        switch (key) {
            case "location":
                return value.map(val => `${val}`).join(", ");
            case "paymentStatus":
                return value.map(val => `Payment ${val.toLowerCase()}`).join(", ");
            case "fulfillmentStatus":
                return value.map(val => `${val.toLowerCase()}`).join(", ");
            case "selectedDates":
                return `${convert(value.start)} to ${convert(value.end)}`;
            default:
                return value;
        }
    }

    function isEmpty(value) {
        if (Array.isArray(value)) {
            return value.length === 0;
        } else {
            return value === "" || value == null;
        }
    };

    function convert(str) {
        var date = new Date(str),
            mnth = ("0" + (date.getMonth() + 1)).slice(-2),
            day = ("0" + date.getDate()).slice(-2);
        return [date.getFullYear(), mnth, day].join("-");
    };

    function renderItem(item) {
        const { id, order, customer, location, date, deliveryDate, amount, payment, fulfillment } = item;
        return (
            <>{(firstItem === item || (firstItem === "")) &&
                <div className="th-list">
                    <div className={selectedItems.length !== 0 ? "tr-list-1 adddiv" : "tr-list-1"}></div>
                    <div className="tr-list"><div>Order</div></div>
                    <div className="tr-list"><div>Date</div></div>
                    <div className="tr-list"><div>Customer</div></div>
                    <div className="tr-list"><div>Amount</div></div>
                    <div className="tr-list"><div>Payment</div></div>
                    <div className="tr-list"><div>Fulfillment</div></div>
                    <div className="tr-list"><div>Delivery Date</div></div>
                    <div className="tr-list"><div>Location</div></div>
                </div>
            }
                <ResourceItem id={id}>
                    <div className="res-tr">
                        <div className="tr-list">
                            <div>
                                {order}
                            </div>
                        </div>
                        <div className="tr-list"><div>
                            {date}
                        </div>
                        </div>
                        <div className="tr-list"><div>
                            {customer}
                        </div>
                        </div>
                        <div className="tr-list"><div>
                            {amount}
                        </div>
                        </div>
                        <div className="tr-list"><div>
                            {payment}
                        </div>
                        </div>
                        <div className="tr-list"><div>
                            {fulfillment}
                        </div>
                        </div>
                        <div className="tr-list"><div>
                            {deliveryDate}
                        </div>
                        </div>
                        <div className="tr-list"><div>{location}</div></div>
                    </div>
                </ResourceItem>
            </>
        );
    }
}

export default Order;