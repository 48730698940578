import * as authService from "../services/authService";
export const GET_ORDERS = "GET_ORDERS";
export const MANUAL_SYNC = "MANUAL_SYNC";
export const ROUTE4ME = "ROUTE4ME";
export const GET_ROUTES = "GET_ROUTES";
export const LOAD_ROUTES = "LOAD_ROUTES";
export const LOAD_R4M = "LOAD_R4M";
export const ROUTE_SUMMARY = "ROUTE_SUMMARY";
export const LOAD_SUMMARY = "LOAD_SUMMARY";
export const ORDER_DETAILS = "ORDER_DETAILS";
export const FAILED = "FAILED";

export const getOrderAction = (data) => {
    return (dispatch) => {
        authService.getOrder(data)
            .then((response) => {
                if (response.status === 200) {
                    dispatch({
                        type: GET_ORDERS,
                        data: response.data,
                    });
                }
            })
            .catch((error) => {
                if (error) {
                    dispatch({ type: FAILED, data: { error_msg: error } });
                }
            })
    }
}

export const manuallySyncAction = (data, data_order) => {
    return (dispatch) => {
        dispatch({
            type: MANUAL_SYNC,
            data: { status: false, loaded_sync: false, data: "", success: "" },
        });
        authService.manuallySync(data)
            .then((response) => {
                if (response.status === 200) {
                    dispatch({
                        type: MANUAL_SYNC,
                        data: { staus: response.data.status, loaded_sync: true, data: response.data.data, success: response.data.success },
                    });
                    if (response.data.success !== false) {
                        authService.getOrder(data_order)
                            .then((response) => {
                                if (response.status === 200) {
                                    dispatch({
                                        type: GET_ORDERS,
                                        data: response.data,
                                    });
                                }
                            })
                            .catch((error) => {
                                if (error) {
                                    dispatch({ type: FAILED, data: { error_msg: error } });
                                }
                            })
                    }
                }
            })
            .catch((error) => {
                if (error) {
                    dispatch({ type: FAILED, data: { error_msg: error } });
                }
            })
    }
}

export const route4meAction = (data) => {
    return (dispatch) => {
        dispatch({
            type: LOAD_R4M,
            data: false
        })
        authService.checkRoute4meByQuery(data)
            .then((response) => {
                if (response.status === 200) {
                    dispatch({
                        type: ROUTE4ME,
                        data: response.data,
                    });
                }
            })
            .catch((error) => {
                if (error) {
                    dispatch({ type: FAILED, data: { error_msg: error } });
                }
            })
    }
}
export const getRoutesAction = (data) => {

    return (dispatch) => {

        dispatch({
            type: LOAD_ROUTES,
            data: false
        })

        authService.getRoutes(data)
            .then((response) => {
                if (response.status === 200) {
                    dispatch({
                        type: GET_ROUTES,
                        data: response.data,
                    });
                }
            })
            .catch((error) => {
                if (error) {
                    dispatch({ type: FAILED, data: { error_msg: error } });
                }
            })
    }
}

export const routeSummaryAction = (data) => {

    return (dispatch) => {
        dispatch({
            type: LOAD_SUMMARY,
            data: false,
        })
        authService.getRouteSummery(data)
            .then((response) => {
                if (response.status === 200) {
                    dispatch({
                        type: ROUTE_SUMMARY,
                        data: response.data,
                    });
                }
            })
            .catch((error) => {
                if (error) {
                    dispatch({ type: FAILED, data: { error_msg: error } });
                }
            })
    }
}