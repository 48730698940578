import axios from 'axios';
var host = window.location.hostname;
var baseUrl = "https://" + host + "/api/"
var tempURL = "http://localhost:8081/api/";
var url = host.includes("localhost") ? tempURL : baseUrl

const BaseService = axios.create(
    {
        baseURL: url
    }
);
export default BaseService;
