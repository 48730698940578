import React, { useCallback, useState } from 'react';
import { Modal, Stack, TextContainer, Icon, TextStyle, Card, Spinner, ResourceItem, ResourceList, Button } from '@shopify/polaris';
import { PrintMajorMonotone } from '@shopify/polaris-icons';
import { useSelector } from 'react-redux';
import * as authService from "../services/authService";
import { renderToString } from 'react-dom/server'
import moment from 'moment';
// import Cookies from "js-cookie";

function RoutesModal(props) {

    var cookie_data = localStorage.getItem("userData") ?
        JSON.parse(localStorage.getItem("userData")) :
        { shop: "shaw-farm.myshopify.com", accessToken: "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" };

    const [userData] = useState(cookie_data);
    const handleScrollBottom = useCallback(() => { }, []);
    const route_summary = useSelector(state => state.orderReducer.route_summary);
    var [loadPrintSummary, setLoadPrintSummary] = useState(false)
    const [value, setValue] = useState({});
    const [selectedItems, setSelectedItems] = useState([]);
    const [loadAllPrint, setLoadAllPrint] = useState(false);

    function handleAllPrint(item) {
        setLoadAllPrint(true);
        orderDetails(item);
    }

    function orderDetails(item) {
        setValue({ [item]: true })
        var data = {
            shop: userData.shop, accessToken: userData.accessToken,
            order_no: item
        }
        authService.getOrderDetails(data)
            .then((response) => {
                if (response.status === 200) {
                    var data = response.data.message;
                    if (data && Array.isArray(data) && data.length !== 0) {

                        var html_data = data && Array.isArray(data) && data.length !== 0 && data.map(((data, key) => {
                            var data1 = data;
                            var date = data1.createdAt ? moment(data1.createdAt).format('YYYY-MM-DD') : "";
                            var total_product_qty = 0;
                            if (data1.lineItems) {
                                if (data1.lineItems.length !== 0) {
                                    for (var i = 0; i < data1.lineItems.length; i++) {
                                        total_product_qty = total_product_qty + data1.lineItems[i].quantity
                                    }
                                }
                            }
                            var product_data = data1 && data1.lineItems && Array.isArray(data1.lineItems) && data1.lineItems.length !== 0 ?
                                data1.lineItems.map((lineItems, i) => {
                                    var unitPrice = lineItems.originalUnitPriceSet && lineItems.originalUnitPriceSet.shopMoney ? (lineItems.originalUnitPriceSet.shopMoney.amount + " " + lineItems.originalUnitPriceSet.shopMoney.currencyCode) : "";
                                    var totalPrice = lineItems.originalTotalSet && lineItems.originalTotalSet.shopMoney ? (lineItems.originalTotalSet.shopMoney.amount + " " + lineItems.originalTotalSet.shopMoney.currencyCode) : "";
                                    var product_title =
                                        lineItems.variant && lineItems.variant.displayName ? lineItems.variant.displayName : "";

                                    return <tr key={i}>
                                        <td style={{ border: "1px solid #ddd" }}>{product_title ? product_title : ""}</td>
                                        <td style={{ border: "1px solid #ddd" }}>{lineItems.quantity ? lineItems.quantity : ""}</td>
                                        <td style={{ border: "1px solid #ddd" }}>{unitPrice ? unitPrice : ""}</td>
                                        <td style={{ border: "1px solid #ddd" }}>{totalPrice ? totalPrice : ""}</td>
                                    </tr>
                                })
                                : <tr><td colSpan="4">No data</td></tr>

                            var address = data1.shippingAddress ? <>
                                <div style={{ fontWeight: "600" }}>{data1.shippingAddress.name ? data1.shippingAddress.name : ""}</div>
                                <div>{data1.shippingAddress.address1 ? data1.shippingAddress.address1 : ""}</div>
                                <div>{data1.shippingAddress.city ? data1.shippingAddress.city : ""}</div>
                                <div>{data1.shippingAddress.province + (data1.shippingAddress.provinceCode ?
                                    " " + data1.shippingAddress.provinceCode : "") + (" , " + data1.shippingAddress.zip)}</div>
                                <div>{data1.shippingAddress.country + (data1.shippingAddress.countryCode ?
                                    " " + data1.shippingAddress.countryCode : "")}</div>
                            </>
                                : ""

                            var customer = data1.customer.displayName ? data1.customer.displayName : "";
                            var totalAmount = data1.totalPriceSet && data1.totalPriceSet.shopMoney ? (data1.totalPriceSet.shopMoney.amount + " " + data1.totalPriceSet.shopMoney.currencyCode) : "";
                            var subTotalAmount = data1.subtotalPriceSet && data1.subtotalPriceSet.shopMoney ? (data1.subtotalPriceSet.shopMoney.amount + " " + data1.subtotalPriceSet.shopMoney.currencyCode) : "";
                            var totalDiscount = data1.totalDiscountsSet && data1.totalDiscountsSet.shopMoney ? (data1.totalDiscountsSet.shopMoney.amount + " " + data1.totalDiscountsSet.shopMoney.currencyCode) : "";
                            var totalShipCharge = data1.totalShippingPriceSet && data1.totalShippingPriceSet.shopMoney ? (data1.totalShippingPriceSet.shopMoney.amount + " " + data1.totalShippingPriceSet.shopMoney.currencyCode) : "";
                            var totalTax = data1.totalTaxSet && data1.totalTaxSet.shopMoney ? (data1.totalTaxSet.shopMoney.amount + " " + data1.totalTaxSet.shopMoney.currencyCode) : "";

                            return <div key={key} style={(response.data.message.length === (key + 1)) ? { padding: "15px 35px", fontSize: "12px", fontFamily: "Times New Roman" }
                                : { padding: "15px 35px", fontSize: "12px", fontFamily: "Times New Roman", pageBreakAfter: 'always !important' }}>
                                <div style={{ textAlign: "center" }}>
                                    <img src="{{logo_base64}}" alt="No img" height="50px" width="70px" />
                                    <div style={{ fontSize: "9px", marginTop: "5px" }}>204 New Boston Road Dracut, MA 01826</div></div>
                                <div style={{ fontWeight: "700", marginTop: "10px", marginBottom: "25px", letterSpacing: "1px", fontSize: "14px", textAlign: "center" }}>Order Receipt</div>
                                <table width="100%" style={{ marginBottom: "20px" }}>
                                    <tr>
                                        <td>
                                            <table width="90%" border='1' style={{ borderCollapse: "collapse", fontSize: "10px" }}>
                                                <tr><td style={{ border: "1px solid #ddd", fontWeight: "600" }}>Order No</td>
                                                    <td style={{ border: "1px solid #ddd", fontWeight: "600" }}>{data1.name}</td></tr>
                                                <tr><td style={{ border: "1px solid #ddd", fontWeight: "600" }}>Order Date</td>
                                                    <td style={{ border: "1px solid #ddd", fontWeight: "600" }}>{date}</td></tr>
                                            </table>
                                        </td>
                                        <td>
                                            <table width="90%" border='1' style={{ borderCollapse: "collapse", marginLeft: "auto", fontSize: "10px" }}>
                                                <tr><td style={{ border: "1px solid #ddd" }}>Total Amount</td>
                                                    <td style={{ border: "1px solid #ddd" }}>{totalAmount}</td></tr>
                                                <tr><td style={{ border: "1px solid #ddd" }}>Delivery Date</td>
                                                    <td style={{ border: "1px solid #ddd" }}>{data1.tag_deliverydate && data1.tag_deliverydate}</td></tr>
                                                <tr><td style={{ border: "1px solid #ddd" }}>Payment Status</td>
                                                    <td style={{ border: "1px solid #ddd" }}>{data1.displayFinancialStatus ? data1.displayFinancialStatus : ""}</td></tr>
                                            </table>
                                        </td>
                                    </tr>
                                </table>
                                <table width="100%" style={{ marginBottom: "20px" }}>
                                    <tr>
                                        <td>
                                            <table width="90%" border='1' style={{ borderCollapse: "collapse", fontSize: "10px" }}>
                                                <tr>
                                                    <td style={{ border: "1px solid #ddd" }}>
                                                        <div style={{ fontWeight: "700" }}>Customer</div>
                                                        <div style={{ fontWeight: "600" }}>{customer}</div>
                                                        <div style={{ color: "blue" }}>{data1.email && data1.email}</div>
                                                        <div>{(data1.shippingAddress && data1.shippingAddress.phone) && data1.shippingAddress.phone}</div>
                                                    </td>
                                                </tr>
                                            </table>
                                        </td>
                                        <td>
                                            <table width="90%" border='1' style={{ borderCollapse: "collapse", marginLeft: "auto", fontSize: "10px" }}>
                                                <tr><td style={{ border: "1px solid #ddd" }}>
                                                    <div style={{ fontWeight: "700" }}>Shipping Address</div>
                                                    <div>{address}</div>
                                                </td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                </table>
                                <table width='100%' border='1' style={{ borderCollapse: "collapse", marginBottom: "20px", fontSize: "10px" }}>
                                    <thead>
                                        <tr >
                                            <th style={{ textAlign: "left", border: "1px solid #ddd" }}>Product</th>
                                            <th style={{ textAlign: "left", border: "1px solid #ddd" }}>Qty</th>
                                            <th style={{ textAlign: "left", border: "1px solid #ddd" }}>Unit Price</th>
                                            <th style={{ textAlign: "left", border: "1px solid #ddd" }}>Total Price</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {product_data}
                                        <tr>
                                            <td style={{ border: "1px solid #ddd", fontWeight: "700" }}>Total :</td>
                                            <td style={{ border: "1px solid #ddd", fontWeight: "700" }}>{total_product_qty}</td>
                                            <td style={{ border: "1px solid #ddd" }}></td>
                                            <td style={{ border: "1px solid #ddd", fontWeight: "700" }}>
                                                {subTotalAmount ? subTotalAmount : ""}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div>
                                    <table width="100%" >
                                        <tr>
                                            <td>
                                                <table width="100%" style={{ fontSize: "10px", minWidth: "200px", maxWidth: "230px", marginRight: "10px" }}>
                                                    <tr style={{ fontWeight: "600" }}>
                                                        <td>
                                                            <span style={{ marginRight: "15px" }}>Sub Total Amount</span>
                                                        </td>
                                                        <td>
                                                            <span>{subTotalAmount ? subTotalAmount : ""}</span>
                                                        </td>
                                                    </tr>
                                                    <tr style={{ fontWeight: "600" }}>
                                                        <td>
                                                            <span style={{ marginRight: "15px" }}>Total Discount</span>
                                                        </td>
                                                        <td>
                                                            <span >{totalDiscount ? totalDiscount : ""}</span>
                                                        </td>
                                                    </tr>
                                                    <tr style={{ fontWeight: "600" }}>
                                                        <td>
                                                            <span style={{ marginRight: "15px" }}>Shipping Fee</span>
                                                        </td>
                                                        <td>
                                                            <span >{totalShipCharge ? totalShipCharge : ""}</span>
                                                        </td>
                                                    </tr>
                                                    <tr style={{ fontWeight: "600" }}>
                                                        <td>
                                                            <span style={{ marginRight: "15px" }}>Total Tax</span>
                                                        </td>
                                                        <td>
                                                            <span>{totalTax ? totalTax : ""}</span>
                                                        </td>
                                                    </tr>
                                                    <tr style={{ fontWeight: "600" }}>
                                                        <td>
                                                            <span style={{ marginRight: "15px" }}>Final Amount</span>
                                                        </td>
                                                        <td>
                                                            <span>{totalAmount ? totalAmount : ""}</span>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </td>
                                            <td >
                                                <table border='1' width="100%" style={{ marginLeft: "auto", borderCollapse: "collapse", fontSize: "10px", minWidth: "120px" }}>
                                                    <tr>
                                                        <td style={{ border: "1px solid #ddd", padding: "2px" }}>
                                                            <div style={{ fontWeight: "700", marginBottom: "3px" }}>Note</div>
                                                            {data1.customer.note || data1.note ?
                                                                <div>
                                                                    {data1.customer.note && <span>{data1.customer.note} < br /> <br /></span>}
                                                                    <span>{data1.note && data1.note}</span>
                                                                </div> :
                                                                <div>-</div>}
                                                        </td>
                                                    </tr>
                                                </table>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                                <div style={{ fontSize: "9px", marginTop: "12px", textAlign: "center" }}>For billing or delivery issues email: lyndieashaw@gmail.com</div>
                            </div >
                        }))

                        var html_string = renderToString(<div>{html_data}</div>);
                        var data_print = {
                            shop: userData.shop, accessToken: userData.accessToken,
                            html: html_string
                        }
                        authService.printHtml(data_print)
                            .then((response) => {

                                setValue({ [item]: false })
                                if (response.status === 200) {
                                    if (response.data.success === true) {
                                        var pdfResult = response.data.b64data;
                                        var w = window.open('', 'pdf', 'width=900,height=800');
                                        w.document.write('<html><body><center>' +
                                            '<a title="Download File" style="font-family: \'Verdana\';color: #333;text-decoration: none;font-weight: 600;" download="File.PDF" href="data:application/pdf;base64,' + pdfResult + '">Download</a>' +
                                            '</center><br>' +
                                            '<object width=100% height=100% type="application/pdf" data="data:application/pdf;base64,' + pdfResult + '">' +
                                            '<embed type="application/pdf" src="data:application/pdf;base64,' + pdfResult + '&embedded=true" id="embed_pdf" style="display:none;"></embed>' +
                                            '</object></body></html>')
                                    }
                                }
                                setLoadAllPrint(false)
                            })
                            .catch((error) => {
                                setLoadAllPrint(false)
                                setValue({ [item]: false })
                                if (error) {
                                    console.log(error)
                                }
                            })

                    } else {
                        setLoadAllPrint(false)
                    }
                }
            })
            .catch((error) => {
                setLoadAllPrint(false)
                setValue({ [item]: false })
                if (error) {
                    console.log(error)
                }
            })
    };

    var summary_display = route_summary.summary && Array.isArray(route_summary.summary) && route_summary.summary.length !== 0 ? route_summary.summary.map(((summary, i) => {
        return <div style={{ marginBottom: '8px' }} key={i}>
            <div>{summary.title} <span style={{ color: "green" }}>X {summary.quantity}</span></div>
        </div>
    })) : [];

    function summaryPrint() {
        setLoadPrintSummary(true);
        var summary_data = route_summary.summary && Array.isArray(route_summary.summary) && route_summary.summary.length !== 0 ? route_summary.summary.map(((summary, i) => {
            return <tr style={{ marginBottom: '8px' }} key={i}>
                <td style={{ border: "1px solid #ddd" }}>{summary.title}</td>
                <td style={{ border: "1px solid #ddd" }}>{summary.quantity}</td>
            </tr>
        })) : <tr><td colSpan="3">No data</td></tr>;

        var total_qty = 0;
        if (route_summary.summary && Array.isArray(route_summary.summary)) {
            if (route_summary.summary.length !== 0) {
                for (var i = 0; i < route_summary.summary.length; i++) {
                    total_qty = total_qty + route_summary.summary[i].quantity
                }
            }
        }
        var html = <div style={{ padding: "35px", fontSize: "11px", fontFamily: "Times New Roman" }}>
            <div style={{ fontWeight: "700", marginBottom: "20px", letterSpacing: "1px", fontSize: "14px", textAlign: "center" }}>Pick up List</div>
            <div><sapn style={{ fontWeight: "700" }}>Date Created :   </sapn><span>{moment().format('YYYY-MM-DD')}</span></div>
            <div><sapn style={{ fontWeight: "700" }}>Route ID :   </sapn><span>{props.singleRoute.id ? ("#" + props.singleRoute.id) : ""}</span></div>
            <div><sapn style={{ fontWeight: "700" }}>Route Name :   </sapn><span>{props.singleRoute.route_name ? (props.singleRoute.route_name) : ""}</span></div>
            <div style={{ marginBottom: "20px", marginTop: "20px" }}>
                <table width="100%" border='1' style={{ borderCollapse: "collapse", borderColor: "inkLighter", fontSize: "11px", margin: "0px auto" }}>
                    <thead>
                        <tr>
                            <th style={{ border: "1px solid #ddd" }}>Product Name</th>
                            <th style={{ border: "1px solid #ddd" }}>Qty</th>
                        </tr>
                    </thead>
                    <tbody>
                        {summary_data}
                        {route_summary.summary && Array.isArray(route_summary.summary) && route_summary.summary.length !== 0 && <tr>
                            <td style={{ border: "1px solid #ddd", fontWeight: "700" }}>Total Qty :</td>
                            <td style={{ border: "1px solid #ddd", fontWeight: "700" }}>{total_qty}</td>
                        </tr>}
                    </tbody>
                </table>
            </div>
        </div>

        var html_string = renderToString(html);

        var data = {
            shop: userData.shop, accessToken: userData.accessToken, html: html_string
        }
        authService.printHtml(data)
            .then((response) => {
                setLoadPrintSummary(false)
                if (response.status === 200) {

                    if (response.data.success === true) {
                        var pdfResult = response.data.b64data;
                        var w = window.open('', "PDF", 'dependent=yes,locationbar=no,scrollbars=no,menubar=no,resizable,screenX=50,screenY=50,width=850,height=800');
                        w.document.write('<html><body><center>' +
                            '<a title="Download File" style="font-family: \'Verdana\';color: #333;text-decoration: none;font-weight: 600;" download="File.PDF" href="data:application/pdf;base64,' + pdfResult + '">Download</a>' +
                            '</center><br>' +
                            '<object width=100% height=100% type="application/pdf" data="data:application/pdf;base64,' + pdfResult + '">' +
                            '<embed type="application/pdf" src="data:application/pdf;base64,' + pdfResult + '&embedded=true" id="embed_pdf" style="display:none;"></embed>' +
                            '</object></body></html>')
                    }
                }
            })
            .catch((error) => {
                setLoadPrintSummary(true)
                if (error) {
                    console.log(error)
                }
            })
    }
    var arrayUnique = function (arr) {
        return arr.filter(function (item, index) {
            return (item) && (arr.indexOf(item) >= index);
        });
    };

    var order_Unique = route_summary.order_nos && Array.isArray(route_summary.order_nos) && route_summary.order_nos.length !== 0 ? arrayUnique(route_summary.order_nos) : [];

    var items = order_Unique && Array.isArray(order_Unique) && order_Unique.length !== 0 ? order_Unique.map((order_nos, i) => {

        var text_container =
            <p className="li-order" key={i}>
                <span className="li-order-name">Order {i + 1} {order_nos}</span>
                <span className="li-order-print" onClick={() => orderDetails([order_nos])}>
                    <span className='pr-icon'><Icon color="inkLighter" source={PrintMajorMonotone} />
                        {
                            value[order_nos] === true &&
                            <span className='order-spinner'><Spinner size="small" color="inkLightest" /></span>}
                    </span>
                </span>
            </p>

        return { text_container, id: order_nos }
    }) : []

    const bulkActions = [];
    var order_print = <div>Print
        {
            (loadAllPrint === true) && <span className="r4m-spinner"><Spinner size="small" color="inkLightest" /></span>}
    </div>

    const promotedBulkActions = [
        {
            content: order_print,
            onAction: () => { handleAllPrint(selectedItems) },
        },
    ];

    function handleR4meFun(id) {
        window.open("https://route4me.com/route?route_id=" + id, "_blank");
    };

    return (
        <>
            <Modal
                large
                open={props.isSelectedRoute}
                onClose={props.handleCloseSelectedRoutes}
                onScrolledToBottom={handleScrollBottom}
                title={"#" + props.singleRoute.id + " - " + props.singleRoute.route_name}
                primaryAction={{
                    content: 'Ok',
                    onAction: props.handleCloseSelectedRoutes,
                }}
                secondaryActions={[
                    {
                        content: 'Cancel',
                        onAction: props.handleCloseSelectedRoutes,
                    },
                ]}
            >
                <Modal.Section>
                    <Stack>
                        <Stack.Item fill>
                            <div className="route-model-item">
                                <Modal.Section >
                                    <div className="r4me-btn">
                                        <Button onClick={() => handleR4meFun(props.singleRoute.id)}>Open in Route4Me</Button>
                                    </div>
                                    <Card>
                                        <Card.Section>
                                            <TextContainer>
                                                <div className="summary-order">
                                                    <span className="li-order-name"><TextStyle variation="strong">Summary</TextStyle></span>
                                                    <span className="li-order-print" onClick={() => summaryPrint()}>
                                                        <Icon color="inkLighter" source={PrintMajorMonotone} /></span>
                                                    {loadPrintSummary &&
                                                        <span className='sum-spinner'><Spinner size="small" color="inkLightest" /></span>}
                                                </div>
                                            </TextContainer>
                                        </Card.Section>
                                        <Card.Section>
                                            <div>{summary_display}</div>
                                        </Card.Section>
                                    </Card>
                                </Modal.Section>

                                <Stack.Item className="stack-res-item">
                                    <div className="stack-res">
                                        {/* {orders_no} */}
                                        <ResourceList
                                            items={items}
                                            renderItem={renderItem}
                                            selectedItems={selectedItems}
                                            onSelectionChange={setSelectedItems}
                                            promotedBulkActions={promotedBulkActions}
                                            bulkActions={bulkActions}
                                        // showHeader
                                        />
                                    </div>
                                </Stack.Item>
                            </div>
                        </Stack.Item>
                    </Stack>
                </Modal.Section>
            </Modal>
        </>);
    function renderItem(item) {
        const { text_container, id } = item;
        return <ResourceItem id={id}>
            {text_container}
        </ResourceItem>
    }
}
export default RoutesModal;